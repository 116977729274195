<template>
    <div>
        <div class="relative h-full">
            <div class="fixed z-20">
                <TinyNavigation :prev="{}" class="pt-6 px-6" />
            </div>

            <div class="flex flex-col max-w-xl mx-auto px-4 pb-10">
                <div class="flex-1 z-0 mt-20 border-l border-r border-t rounded-t border-gray-700 bg-gradient-to-b from-scorelitorange to-scorelitred">
                    <div class="avatar relative overflow-hidden rounded-t">
                        <img class="object-cover w-full" :src="image" alt="">
                        <div class="absolute inset-x-0 bottom-0 h-24 bg-gradient-to-b from-transparent to-black"></div>
                    </div>
                </div>
                <div class="flex-1 border-l border-r border-b rounded-b border-gray-700 z-20">
                    <div class="flex flex-row justify-center -mt-16">
                        <div class="flex flex-row items-center">
                            <div v-if="expert.position">
                                <div class="inline-flex items-center relative text-center w-16 md:w-20">
                                    <img src="@/assets/icons/shield_with_gradient.svg" alt="" class="w-16 h-16 md:w-20 md:h-20">
                                    <div class="centered text-white font-bold text-base md:text-lg z-20">
                                        {{ expert.position }}
                                    </div>
                                </div>
                            </div>
                            <span class="text-white ml-8 text-xl">{{ expert.first_name }} <span class="font-bold">{{ expert.last_name }}</span></span>
                        </div>
                    </div>
                    <h3 class="flex-auto text-center text-white pt-16 mb-2 font-medium text-base md:text-lg">{{ t('about') }} {{ expert.first_name }}</h3>
                    <p class="flex-auto text-center text-white px-8 md:px-16 text-sm md:text-base">{{ expert.about }}</p>

                    <h3 class="flex-auto text-center text-white pt-10 mb-2 font-medium text-base md:text-lg">{{ t('expertise') }}</h3>
                    <p class="flex-auto text-center text-white px-8 md:px-16 text-sm md:text-base">{{ expert.expertise }}</p>
                    <div class="flex-1 text-center text-white pt-10">
                        <div v-for="(stat, index) in stats" :key="index">
                            <h6 class="pb-2 text-sm md:text-base"><span class="text-scorelitorange font-medium mr-2">{{ stat.key }}</span><span class="font-normal">{{ stat.val }}</span></h6>
                        </div>
                        <router-link :to="{name: 'review.create', query: {coach_id: id}}" class="block w-full p-8 mt-10 rounded-md shadow-sm">
                            <button type="button" class="button w-full">
                                {{ t('choose_expert') }}
                            </button>
                        </router-link>
                    </div> 
                </div> 
            </div>
        </div>
    </div>
</template>

<script>
import { showExpert } from "@/helpers/api/ExpertHelper";
import TinyNavigation from "@/components/TinyNavigation";
import store from "@/store";
import useI18n from "@/modules/globalI18n";
import { humanReadablePriceCeil } from "@/helpers/CurrencyHelper"

export default {
    name: "ExpertShow",
    components: { TinyNavigation },
    props: ['id'],
    data(){
        return {
            expert: {},
        };
    },
    setup() {
        return useI18n({
            viewPrefix: "ExpertShow"
        });
    },
    created(){
        showExpert(this.id)
            .then(expert => {
                window.gtag('event', 'coach', {
                    'event_category': 'pageview',
                    'event_label': expert.first_name + expert.last_name
                });

                this.expert = expert;
            })
            .catch(err =>{
                console.error(err);
            });
    }, 
    computed: {
        image: function (){
            if(!this.expert){
                return null;
            }
            if (this.expert === undefined || !this.expert.image){
                return "";
            }
            return this.expert.image;
        },
        stats: function (){
            if (!this.expert){
                return [];
            }

            let stats = [
                {
                    key: this.t('stats.age'),
                    val: this.expert.age + ' ' + this.t('years'),
                },
                {
                    key: this.t('stats.active'),
                    val: this.expert.active ? this.t('yes'): this.t('no'),
                },
                {
                    key: this.t('stats.price'),
                    val: this.salePriceString ,
                },
            ];
            if(this.expert.completed_reviews !== 0){
                stats.push({
                    key: this.t('stats.response_time'),
                    val: Math.round(this.expert.response_time / 60 / 24),
                })
            }
            return stats;                
        },
        user: () => {
            return store.getters['getUser'];
        },
        salePriceString() {
            return humanReadablePriceCeil(Math.ceil(this.expert.sale_hourly_price)*100);
        },
    }
}
</script>

<style scoped>
    .centered {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .avatar {
        max-height: 30rem;
    }
</style>