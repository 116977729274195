function humanReadablePrice(price)
{
    const isWholeValue = price % 100 === 0;

    const valueStr = isWholeValue ? price / 100 : (price / 100).toFixed(2);

    const valueStrWithCurrency = "€ " + valueStr;

    let str = valueStrWithCurrency.replace('.', ',');

    if (isWholeValue){
        str += ",-";
    }

    return str;
}

function humanReadablePriceCeil(price)
{
    const isWholeValue = price % 100 === 0;

    const valueStr = isWholeValue ? price / 100 : (price / 100).toFixed(2);

    const valueStrWithCurrency = "€ " + Math.ceil(valueStr / 10) * 10;

    let str = valueStrWithCurrency.replace('.', ',');

    if (isWholeValue){
        str += ",-";
    }

    return str;
}

export { humanReadablePrice, humanReadablePriceCeil };